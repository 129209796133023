
























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import Vue from "vue";
import _ from "lodash";
import { ulid } from "ulid";
import VueScrollTo from "vue-scrollto";

import store from "../../store/shop";
import ShopAPI from "../../common/api/api_shop";
import notificationMixin from "../../mixin/notification";
import { OrderStatus } from "../../model/entity/orderSetting";
import { ServedCode } from "../../model/entity/orderSetting";
import {
  DiscountTypes,
  TypeNames,
  DiscountTypeNames1,
  DiscountTypeNames2,
} from "../../model/entity/paymentSetting";
import { TranslateServiceBuilder } from "../../services/Translate/translateServiceBuilder";
import { SessionStatus } from "../../model/entity/tableSessionSetting";
import { PrintBit } from "../../model/entity/printJobSetting";

Vue.use(VueScrollTo);

const translate = TranslateServiceBuilder.Instance()._;
const api = new ShopAPI();

const businessLogic = {
  noPayment: function (orders) {
    let rtn = [];
    for (const row of orders) {
      if (row.status === OrderStatus.STATUS_ORDERED) {
        rtn.push(row);
      }
      if (row.status === OrderStatus.STATUS_CANCELED) {
        rtn.push(row);
      }
    }
    return rtn;
  },
};
Vue.directive("enhance-defaulot-value", {
  bind: function (el) {
    el.addEventListener("focus", function () {
      const value = el.value;
      if (value == 0) {
        el.value = "";
      }
    });
    el.addEventListener("blur", function () {
      const value = el.value;
      if (value == "") {
        el.value = 0;
      }
    });
  },
});

export default Vue.component("Check", {
  components: {
    QButton: () => import("../../components/elements/QButton.vue"),
    SHCheckCardSkeleton: () =>
      import("../../components/components/SHCheckCardSkeleton.vue"),
    QTranslate: () => import("../../components/elements/QTranslate.vue"),
    QCartListTotal: () => import("../../components/elements/QCartListTotal.vue"),
    SHDisplayPopup: () => import("../../components/components/SHCheckDisplayConfirmPopup.vue"),
  },
  mixins: [notificationMixin],
  data() {
    return {
      item: {},
      tableSessionId: this.$route.params.table_session_id,
      newReceiptPaperDetail: {},
      currentDetail: {},
      modal: {
        data: {
          summary: {},
          summary8: {},
          summary10: {},
          order: {},
          payment: {
            id: "",
            orders: [],
            total_price: 0,
            staff_id: "",
            discount_type: 0,
            discount_type1: 0,
            discount_type2: "",
            discount_detail: 0,
            discount_price: 0,
            discount_price8: 0,
            discount_price10: 0,
            change_price: 0,
            payment_details: [
              {
                type: "",
                cash_price: 0,
                change_price: 0,
              },
              {
                type: "",
                cash_price: 0,
                change_price: 0,
              }
            ],
          },
          receipt_paper: {
            id: null,
            table_session_id: null,
            staff_id: null,
            payment_id: null,
            payment: {},
            price: null,
            receipt_paper_details: [],
          },
          settle: {
            payment: {},
          },
          menu_options: undefined,
          receipt_paper_details: [],
          price: null,
          count: 1,
        },
        errors: {
          order: {},
          payment: {},
          receipt_paper: {
            price: {},
          },
        },
      },
      scrollNo: "",
      hash: "",
      registerDisplay: {},
      noDisplay: false,

      errors: "",
      mode: "",
      isLoading: false,
      taxes: [8, 10],
      TypeNames: TypeNames,
      DiscountTypeNames1: DiscountTypeNames1,
      DiscountTypeNames2: DiscountTypeNames2,
      show_contents: [],

      staffId: store.state.staff.id,
      langKey: store.state.langKey,
    };
  },
  mounted() {
    api.set_token(store.state.token);
    this.fetchOrders()
        .then(() => {
            // when table session is paying status => open checkout popup automatically.
            if (this.item.status === SessionStatus.STATUS_PAYING) {
                this.handleClickPaymentConfirm();
            };
          const hash = this.$route.hash
          if (hash && hash.match(/^#.+$/)) {
            this.$scrollTo(hash);
            };
        });
  },
  watch: {
    tableSessionChange(newVal, oldVal) {
      if (newVal != oldVal && store.state.pageChange.value == this.tableSessionId) {
        console.log('force update page')
        this.fetchOrders(false);
      }
    },
    discountPrice: function (newVal, oldVal) {
      this.openDisplay();
    }
  },
  computed: {
    prefix() {
      return this.$route.params.prefix;
    },
    shoppix() {
      return this.$route.params.shoppix;
    },
    hasTmpOrders: function () {
      if (!Object.keys(this.item).length) {
        return false;
      }
      return this.item.tmp_orders && this.item.tmp_orders.length > 0;
    },
    hasNoPaymentOrder: function () {
      if (!Object.keys(this.item).length) {
        return false;
      }
      let orders = this.item.no_paid_orders;
      let rtn = [];
      for (const row of orders) {
        if (row.status === OrderStatus.STATUS_ORDERED) {
          rtn.push(row);
        }
      }
      return rtn.length > 0;
    },
    hasPayments: function () {
      if (!Object.keys(this.item).length) {
        return false;
      }
      return this.item.payments.length > 0;
    },
    hasNoPaymentKobetsu: function () {
      let orders = _.groupBy(this.item.no_paid_orders, "code");
      if (Object.keys(orders).length === 0) {
        return false;
      }
      let rtn = [];
      for(let key of Object.keys(orders)) {
        for (const row of orders[key]) {
          if (row.status === OrderStatus.STATUS_ORDERED) {
            rtn.push(row);
          }
        }
      }
      return rtn.length > 0;
    },
    hasPaymentsKobetsu: function () {
      let rtn = [];
      if (this.devicePay.length === 0) {
        return rtn;
      }
      for (let row of this.devicePay) {
        for (let order of row.orders) {
          if ((order.code !== null) && (order.status === OrderStatus.STATUS_PAID)) {
            rtn.push(row);
          }
        }
      }
      return rtn.length > 0;
    },
    deviceCodes: function () {
      return _.groupBy(this.item.no_paid_orders, "code");
    },
    discountPrice: function () {
      this.modal.data.payment.discount_price = 0;
      this.modal.data.payment.discount_price8 = 0;
      this.modal.data.payment.discount_price10 = 0;
      if (this.modal.data.payment.discount_type1 === String(DiscountTypes.DISCOUNT_NONE)) {
        this.modal.data.payment.discount_type = "0";
        this.modal.data.payment.discount_type2 = "";
        return;
      }
      this.modal.data.payment.discount_type = this.modal.data.payment.discount_type1 + this.modal.data.payment.discount_type2;
      // 8%の商品の合計額
      let discount8_target = this.modal.data.summary8.include;
      // 10%の商品の合計額
      let discount10_target = this.modal.data.summary10.include;
      // 割引額
      // %の場合
      if (this.modal.data.payment.discount_type2 === String(DiscountTypes.DISCOUNT_PER)) {
        if (this.modal.data.payment.discount_type1 === String(DiscountTypes.DISCOUNT_8PER)) {
          this.modal.data.payment.discount_price8 = _.round(
            (this.modal.data.payment.discount_detail * discount8_target) / 100, 0
          );
        } else if (this.modal.data.payment.discount_type1 === String(DiscountTypes.DISCOUNT_10PER)) {
          this.modal.data.payment.discount_price10 = _.round(
            (this.modal.data.payment.discount_detail * discount10_target) / 100, 0
          );
        } else if (this.modal.data.payment.discount_type1 === String(DiscountTypes.DISCOUNT_COMMON)) {
          this.modal.data.payment.discount_price8 = _.round(
            (this.modal.data.payment.discount_detail * discount8_target) / 100, 0
          );
          this.modal.data.payment.discount_price10 = _.round(
            (this.modal.data.payment.discount_detail * discount10_target) / 100, 0
          );
        }
        return (this.modal.data.payment.discount_price =
          this.modal.data.payment.discount_price8 + this.modal.data.payment.discount_price10);
      }
      // 円の場合
      let discount_price = 0;
      if (this.modal.data.payment.discount_type2 === String(DiscountTypes.DISCOUNT_YEN)) {
        if (this.modal.data.payment.discount_type1 === String(DiscountTypes.DISCOUNT_8PER)) {
          if (this.modal.data.payment.discount_detail > discount8_target) {
            discount_price = discount8_target;
          } else {
            discount_price = this.modal.data.payment.discount_detail;
          }
          this.modal.data.payment.discount_price8 = discount_price;
        } else if (this.modal.data.payment.discount_type1 === String(DiscountTypes.DISCOUNT_10PER)) {
          if (this.modal.data.payment.discount_detail > discount10_target) {
            discount_price = discount10_target;
          } else {
            discount_price = this.modal.data.payment.discount_detail;
          }
          this.modal.data.payment.discount_price10 = discount_price;
        } else if (this.modal.data.payment.discount_type1 === String(DiscountTypes.DISCOUNT_COMMON)) {
          let discount_price8 =
            _.round(discount8_target / (discount8_target + discount10_target) * this.modal.data.payment.discount_detail, 0);
          if (discount_price8 > discount8_target) {
            discount_price8 = discount8_target;
          }
          let discount_price10 =
           _.round(discount10_target / (discount8_target + discount10_target) * this.modal.data.payment.discount_detail, 0);
          if (discount_price10 > discount10_target) {
            discount_price10 = discount10_target;
          }
          discount_price = discount_price8 + discount_price10;
          this.modal.data.payment.discount_price8 = discount_price8;
          this.modal.data.payment.discount_price10 = discount_price10;
        }
        return (this.modal.data.payment.discount_price = discount_price);
      }
      return 0;
    },
    discountDetail: {
      get() {
        if (
          this.modal.data.payment.discount_type1 ===
          String(DiscountTypes.DISCOUNT_NONE)
        ) {
          return (this.modal.data.payment.discount_detail = 0);
        }
        return this.modal.data.payment.discount_detail;
      },
      set(value) {
        this.modal.data.payment.discount_detail = value;
      },
    },
    accountingPrice: function () {
      let rtn = this.modal.data.summary.include;
      if (
        this.modal.data.payment.discount_type ===
        String(DiscountTypes.DISCOUNT_NONE)
      ) {
        this.modal.data.payment.discount_price = 0;
      }
      rtn = rtn - this.modal.data.payment.discount_price;
      if (rtn < 0) {
        return 0;
      }
      return rtn;
    },
    changePrice: function () {
      this.modal.data.payment.total_price = this.modal.data.summary.include;
      if (isNaN(parseInt(this.modal.data.payment.discount_price))) {
        return "---";
      }
      let accounting =
        this.modal.data.payment.total_price -
        parseInt(this.modal.data.payment.discount_price);
      if (accounting < 0) {
        accounting = 0;
      }
      var cash_price = 0;
      for(let i = 0; i < this.modal.data.payment.payment_details.length; i++) {
        if (this.modal.data.payment.payment_details[i].cash_price !== "") {
          cash_price += parseInt(this.modal.data.payment.payment_details[i].cash_price);
        }
      }
      this.modal.data.payment.change_price = cash_price - accounting;

      return this.modal.data.payment.change_price;
    },
    notDevicePay: function () {
      let rtn = [];
      if (!Object.keys(this.item).length) {
      }
      for (let row of this.item.payments) {
        if (row.device_code === null) {
          rtn.push(row);
        }
      }
      return rtn;
    },
    devicePay: function () {
      let rtn = [];
      if (!Object.keys(this.item).length) {
        return rtn;
      }
      for (let row of this.item.payments) {
        if (row.device_code !== null) {
          rtn.push(row);
        }
      }
      return rtn;
    },
    tableSessionChange: function() {
      return store.state.pageChange.check;
    },
    isReceipt(): boolean {
      const setting = store.state.shopSetting;
      if (setting === null) {
        return false;
      }

      return setting.is_receipt;
    },
    isModalCancel(): boolean {
      return this.mode === "cancel";
    },
  },
  methods: {
    // Header
    setTableSession() {
      if (!this.item) {
        return;
      }
      store.commit("setTableSession", this.item);
    },

    handleClickPaymentConfirm() {
        this.mode = 'payment-confirm';
    },
    handleClickPayment(orders = null, device_code = null, force = false) {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      let message = "";
      this.hash = this.getHash();
      api
        .display_start({ hash: this.hash, force })
        .then(async (response) => {
          this.isLoading = false;
          if (response.data.status === "another") {
            return this.anotherPaying(orders, device_code);
          }
          this.registerDisplay = response.data.data.register_display;

          await this.setPaymentModal(orders, device_code);
          this.openDisplay();
        })
        .catch((err) => {
          this.isLoading = false;
          message =
            err.response.data.message ||
            translate("hall-0048", "データの保存に失敗しました。");
          this.showErrorNotification(message);
          this.errors = message;
        });
    },
    handleClickSubmitSettle(payment) {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;

      this.submitSettle(payment);
    },
    handleClickReceipt(payment) {
      if (this.isLoading) {
        return;
      }

      this.mode = "receipt";

      this.modal.data.receipt_paper.id = ulid();
      this.modal.data.receipt_paper.table_session_id = this.item.id;
      this.modal.data.receipt_paper.staff_id = this.staffId;
      this.modal.data.receipt_paper.payment_id = payment.id;
      this.modal.data.receipt_paper.payment = payment;
      this.modal.data.receipt_paper.price = null;
      this.modal.data.receipt_paper.receipt_paper_details = [];
      for (let i = 0; i < this.item.payments.length; i++) {
        let papers = this.item.payments[i].receipt_papers;
        for (let j = 0; j < papers.length; j++) {
          let details = papers[j].receipt_paper_details;
          for (let k = 0; k < details.length; k++) {
            this.modal.data.receipt_paper_details.push(details[k]);
          }
        }
      }
    },
    handleClickCancelTableSession() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      this.callCancelTableSession();
    },
    handleClickModifyOrder(item) {
      if (this.isLoading) {
        return;
      }
      if (item.status !== OrderStatus.STATUS_ORDERED) {
        return;
      }

      this.mode = "edit";
      this.modal.data.menu_options = item.menu.menu_options;
      this.modal.data.order = _.cloneDeep(item);
    },
    handleClickSubmitPayment: function () {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      // if (this.modal.data.payment.type !== "cash") {
      //   this.modal.data.payment.cash_price =
      //     this.modal.data.payment.total_price -
      //     this.modal.data.payment.discount_price;
      //   this.modal.data.payment.change_price = 0;
      // }
      this.submitPayment();
    },
    handleClickSubmitReceipt: function () {
      if (this.isLoading) {
        return;
      }
      if (this.checkPrice() === false) {
          this.isLoading = false;
          this.showErrorNotification(translate("hall-0046", "保存に失敗しました。"));
          let message = translate("hall-0271", "領収書金額 が正しくありません");
          this.modal.errors.receipt_paper.price = {_empty: message};
          return;
      }
      this.modal.data.receipt_paper.price = this.modal.data.price;
      this.submitReceipt();
    },
    handleClickIncrease: function () {
      if (this.isLoading) {
        return;
      }

      this.updateQuantity(+1);
    },
    handleClickDecrease: function () {
      if (this.isLoading) {
        return;
      }

      this.updateQuantity(-1);
    },
    handleClickSubmitModify: function () {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      this.submitModify();
    },
    handleClickSubmitDelete: function () {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      this.submitDelete();
    },
    handleClickSubmitRecepted: function () {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      this.submitRecepted();
    },
    handleClickCloseModal: function () {
      this.closeModal();
      if (this.hash != "") {
        this.endDisplay();
      }
    },
    handleClickAddDetail: function () {
      this.addDetail();
    },
    handleClickDelDetail: function (index) {
      this.modal.data.receipt_paper.receipt_paper_details.splice(index, 1);
    },
    handleClickTotal: function () {
      let rtn = _.sumBy(this.modal.data.receipt_paper.receipt_paper_details, "price");
      this.modal.data.price = this.modal.data.receipt_paper.payment.receiptable_price + rtn;
      this.modal.data.count = 1;
    },
    handleClickAccounting: function (index) {
      const charge = this.changePrice + this.modal.data.payment.payment_details[index].cash_price * -1;
      if (charge < 0) {
        this.modal.data.payment.payment_details[index].cash_price = charge * -1;
      }
    },

    handleClickForceCancel() {
      if (this.isLoading) {
        return;
      }
      this.mode = "cancel";
    },
    handleClickCloseEditor() {
        this.closeModal();
    },

    closeModal() {
      this.mode = "";
      this.isLoading = false;
      this.modal.data.order = {};
      this.modal.data.price = null;
      this.modal.data.count = 1;
      this.modal.data.receipt_paper_details = [];
      this.modal.data.receipt_paper.receipt_paper_details = [];
    },
    redirectToHome() {
      if (store.getters.canUseEatIn) {
        this.$router.push(
          `/${this.$route.params.prefix}/${this.$route.params.shoppix}/home`
        );
      } else if (store.getters.canUseTakeout) {
        this.$router.push(
          `/${this.$route.params.prefix}/${this.$route.params.shoppix}/takeout`
        );
      }
    },
    updateQuantity: function (offset) {
      const quantity = Math.max(1, this.modal.data.order.quantity + offset);
      this.modal.data.order.quantity = quantity;
    },
    paymentedSummary(payment_id) {
      if (
        !this.item ||
        !this.item.paymented ||
        !this.item.paymented[payment_id]
      ) {
        return [];
      }

      return this.item.paymented[payment_id];
    },
    optionValueRadio(option_menu) {
      const result = _.find(this.modal.data.order.options, function (row: any) {
        if (!row.menus_menu_option) {
          return false;
        }
        return option_menu.id === row.menus_menu_option.id;
      });
      return result ? true : false;
    },
    optionValueCheckbox(option_menu) {
      const result = _.findIndex(
        this.modal.data.order.options,
        function (row: any) {
          return option_menu.id === row.menus_menu_option_id;
        }
      );
      return result !== -1;
    },
    optionChangeRadio(event, option) {
      _.remove(this.modal.data.order.options, function (row: any) {
        if (!row.menus_menu_option) {
          return false;
        }
        return option.id === row.menus_menu_option.menu_option_id;
      });
      this.modal.data.order.options.push({
        menus_menu_option_id: _.clone(event.target.value),
        menus_menu_option: {
          menu_option_id: option.id,
        },
        quantity: this.modal.data.order.quantity,
      });
    },
    optionChangeCheckbox(event, option) {
      if (!event.target.checked) {
        const result = _.findIndex(
          this.modal.data.order.options,
          function (row: any) {
            return event.target.value === row.menus_menu_option_id;
          }
        );
        if (result !== -1) {
          this.modal.data.order.options.splice(result, 1);
        }
      } else {
        this.modal.data.order.options.push({
          menus_menu_option_id: _.clone(event.target.value),
          menus_menu_option: {
            menu_option_id: option.id,
          },
          quantity: this.modal.data.order.quantity,
        });
      }
    },
    toggle: function (data) {
      if (this.inShowContents(data)) {
        this.show_contents = this.show_contents.filter((n) => n !== data);
      } else {
        this.show_contents.push(data);
      }
    },
    inShowContents: function (key) {
      return this.show_contents.indexOf(key) >= 0;
    },
    addDetail: function () {
      this.currentDetail = _.cloneDeep(this.newReceiptPaperDetail);
      this.modal.data.receipt_paper.receipt_paper_details.push(this.currentDetail);
    },
    checkPrice: function () {
      let rtn = _.sumBy(this.modal.data.receipt_paper.receipt_paper_details, "price");
      let price = this.modal.data.receipt_paper.payment.receiptable_price + rtn;
      if (this.modal.data.price * this.modal.data.count > price) {
        return false;
      }
      return true;
    },
    errorForm(value) {
      if (!value._empty) {
       return "";
      }
      return "error";
    },

    submitPayment() {
      let message = "";
      const payment = this.modal.data.payment;
      payment.print_rule_id = store.state.printRule[PrintBit.TYPE_SETTLE_AND_RECEIPT];
      api
        .payment_edit(
          { payment },
          { payment_id: this.modal.data.payment.id },
        )
        .then((response) => {
          this.mode = "payment_finish";
          this.modal.data.settle.payment = response.data.payment;
          this.payDisplay();
          this.fetchOrders();
        })
        .catch((err) => {
          message =
            err.response.data.message ||
            translate('hall-0046', '保存に失敗しました。');
          this.showErrorNotification(message);
          this.modal.errors.payment = message;
          this.isLoading = false;
        });
    },
    submitPaying() {
      let message = "";
      return api
        .table_sessions_paying({ table_session_id: this.tableSessionId })
        .then((response) => {
          return this.fetchOrders();
        })
        .catch((err) => {
          message =
            err.response.data.message ||
            translate("hall-0046", "保存に失敗しました。");
          this.showErrorNotification(message);
          this.errors = message;
          this.isLoading = false;
        });
    },
    submitRecepted() {
      let message = "";
      api
        .table_sessions_recepted({ table_session_id: this.tableSessionId })
        .then((response) => {
          this.closeModal();
          if (this.hash != "") {
            this.endDisplay();
          }
          this.fetchOrders();
        })
        .catch((err) => {
          message =
            err.response.data.message ||
            translate("hall-0046", "保存に失敗しました。");
          this.showErrorNotification(message);
          this.errors = message;
          this.isLoading = false;
        });
    },
    submitSettle(payment) {
      let message = "";
      payment.print_rule_id = store.state.printRule[PrintBit.TYPE_SETTLE_AND_RECEIPT];
      api
        .receipt_settle({ payment: payment })
        .then((response) => {
          this.mode = "settle_finish";
          if (this.hash != "") {
            this.endDisplay();
          }
          this.fetchOrders();
        })
        .catch((err) => {
          message =
            err.response.data.message ||
            translate('hall-0046', '保存に失敗しました。');
          this.showErrorNotification(message);
          this.modal.errors.payment = message;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    submitReceipt() {
      let message = "";
      api
        .receipt_receipt({
          receipt_paper: this.modal.data.receipt_paper,
          count: this.modal.data.count,
          print_rule_id: store.state.printRule[PrintBit.TYPE_SETTLE_AND_RECEIPT],
        })
        .then((response) => {
          this.mode = "receipt_finish";
          this.fetchOrders();
        })
        .catch((err) => {
          message =
            err.response.data.message ||
            translate('hall-0046', '保存に失敗しました。');
          this.showErrorNotification(message);
          this.modal.errors.payment = message;
          this.modal.errors.receipt_paper = err.response.data.errors.receipt_paper;
          this.isLoading = false;
        });
    },
    submitModify() {
      let message = "";
      api
        .order_edit({
          datas: { order: this.modal.data.order, staff: this.staffId },
        })
        .then((response) => {
          message = response.data.message;
          this.showSuccessNotification(message);
          this.closeModal();
          this.fetchOrders();
        })
        .catch((err) => {
          message =
            err.response.data.message ||
            translate("hall-0046", "保存に失敗しました。");
          this.showErrorNotification(message);
          this.modal.errors.order = message;
          this.isLoading = false;
        });
    },
    submitDelete: function () {
      let message = "";
      api
        .order_delete({ order: this.modal.data.order })
        .then((response) => {
          message = response.data.message;
          this.showSuccessNotification(message);
          this.closeModal();
          this.fetchOrders();
        })
        .catch((err) => {
          message = err.response.data.message || "削除に失敗しました。";
          this.showErrorNotification(message);
          this.modal.errors.order = message;
          this.isLoading = false;
        });
    },

    callCancelTableSession() {
      let message = "";
      api
        .table_sessions_cancel({ table_session_id: this.tableSessionId })
        .then((response) => {
          message = this.hasPayments ? "座席を精算済みにしました。" : "受付をキャンセルしました。";
          this.showSuccessNotification(message);
          this.closeModal();
          this.redirectToHome();
        })
        .catch((err) => {
          message =
            err.response.data.message ||
            translate('hall-0046', '保存に失敗しました。');
          this.showErrorNotification(message);
          this.errors = message;
          this.isLoading = false;
        });
    },
    getHash(digit) {
      let patterns = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
      let val = '';
      if (typeof digit === 'undefined') digit = 16;
      for (var i = 0; i < digit; i++) {
          val += patterns[Math.floor(Math.random() * patterns.length)] + '';
      }
      return val;
    },
    anotherPaying(orders, device_code) {
      this.mode = "another";
      this.modal.data.orders = orders;
      this.modal.data.device_code = device_code;
    },
    openDisplay() {
      if (this.noDisplay) {
        return;
      }
      this.isLoading = true;
      let message = "";
      api
        .display_open({ total: this.accountingPrice, hash: this.hash })
        .then((response) => {
          this.isLoading = false;
          this.registerDisplay = response.data.data.register_display;
        })
        .catch((err) => {
          this.isLoading = false;
          message =
            err.response.data.message ||
            translate("hall-0048", "データの保存に失敗しました。");
          this.showErrorNotification(message);
          this.errors = message;
        });
    },
    payDisplay() {
      if (this.noDisplay) {
        return;
      }
      this.isLoading = true;
      let message = "";
      let price = 0;
      _.forEach(this.modal.data.payment.payment_details, function(row) {
        price += parseInt(row.cash_price);
      })
      api
        .display_pay({
          total: this.accountingPrice,
          cash: String(price),
          change: this.changePrice,
          hash: this.hash,
        })
        .then((response) => {
          this.isLoading = false;
          this.registerDisplay = response.data.data.register_display;
        })
        .catch((err) => {
          this.isLoading = false;
          message =
            err.response.data.message ||
            translate("hall-0048", "データの保存に失敗しました。");
          this.showErrorNotification(message);
          this.errors = message;
        });
    },
    endDisplay() {
      if (this.noDisplay) {
        return;
      }
      this.isLoading = true;
      let message = "";
      this.noDisplay = false;
      api
        .display_end({ hash: this.hash })
        .then((response) => {
          this.isLoading = false;
          this.registerDisplay = response.data.data.register_display;
          this.hash = "";
        })
        .catch((err) => {
          this.isLoading = false;
          message =
            err.response.data.message ||
            translate("hall-0048", "データの保存に失敗しました。");
          this.showErrorNotification(message);
          this.errors = message;
        });
    },
    async setPaymentModal(orders, device_code) {
      if (device_code === null) {
        this.isLoading = true;
        await this.submitPaying();
      }
      if (orders === null) {
        orders = this.item.no_paid_orders;
      }

      this.mode = "payment";

      for(let i = 0; i < this.modal.data.payment.payment_details.length; i++) {
        this.modal.data.payment.payment_details[i].type = "";
        this.modal.data.payment.payment_details[i].cash_price = 0;
        this.modal.data.payment.payment_details[i].change_price = 0;
      }
      this.modal.data.payment.total_price = 0;
      this.modal.data.payment.discount_type = 0;
      this.modal.data.payment.discount_type1 = 0;
      this.modal.data.payment.discount_type2 = "";
      this.modal.data.payment.discount_detail = 0;
      this.modal.data.payment.discount_price = 0;
      this.modal.data.payment.change_price = 0;

      this.modal.data.payment.id = ulid();
      this.modal.data.payment.table_session_id = this.item.id;
      this.modal.data.payment.staff_id = this.staffId;
      this.modal.data.payment.device_code = device_code;
      this.modal.data.payment.orders = _.cloneDeep(
        businessLogic.noPayment(orders)
      );
      let summary = {};
      if (device_code && this.item.deviceCodes[device_code]) {
        summary = this.item.deviceCodes[device_code];
      } else {
        summary = this.item.noPayment;
      }
      this.modal.data.summary = _.find(summary, (row: any) => {
        return row.tax_rate === null;
      });
      this.modal.data.summary8 = _.find(summary, (row: any) => {
        return row.tax_rate === 8;
      });
      this.modal.data.summary10 = _.find(summary, (row: any) => {
        return row.tax_rate === 10;
      });
    },
    noSwitchDisplay() {
      const orders = this.modal.data.orders;
      const device_code = this.modal.data.device_code;
      this.modal.data.orders = null;
      this.modal.data.device_code = null;
      this.noDisplay = true;

      this.setPaymentModal(orders, device_code);
    },

    switchDisplay() {
      const orders = this.modal.data.orders;
      const device_code = this.modal.data.device_code;
      this.modal.data.orders = null;
      this.modal.data.device_code = null;
      this.handleClickPayment(orders, device_code, true);
    },

    fetchOrders(showLoading = true) {
      showLoading && (this.isLoading = true);
      let message = "";
      return api
        .hallCheck({
          lang: this.langKey,
          table_session_id: this.tableSessionId,
        })
        .then((response) => {
          if (response.data.status !== "success") {
            message =
              response.data.message ||
              translate("hall-0048", "データの取得に失敗しました。");
            this.showErrorNotification(message);
            this.errors = message;
            return;
          }
          this.isLoading = false;
          this.item = response.data.data.tableSession;
          this.newReceiptPaperDetail = response.data.data.newReceiptPaperDetail;
          this.setTableSession();
        })
        .catch((err) => {
          this.isLoading = false;
          message =
            err.response.data.message ||
            translate("hall-0048", "データの取得に失敗しました。");
          this.showErrorNotification(message);
          this.errors = message;
        });
    },
  },
  filters: {
    numberFormat(number) {
      if (typeof number !== "number") {
        return number;
      }
      return number.toLocaleString();
    },
    addIndex(index) {
      return parseInt(index) + 1;
    },
    sumAccounting(summary, price) {
      let rtn = (
        _.find(summary, (row: any) => {
          return row.tax_rate === null;
        }) || { include: 0 }
      ).include;
      rtn = rtn - price;
      if (rtn < 0) {
        return 0;
      }
      return rtn;
    },
    filterdReceiptAccounting(payment, price) {
      return payment.total_price - payment.discount_price;
    },
    filterdReceiptOther(detail, code) {
      let price = detail.unit_price * detail.quantity;
      if (code.is_naizei === true) {
        detail.is_naizei = true;
        let exclude = price / ((detail.tax_rate + 100) / 100);
        switch (code.tanmatsu) {
          case 2:
            exclude = Math.floor(exclude);
            break;
          case 1:
            exclude = Math.ceil(exclude);
            break;
          default:
            exclude = Math.round(exclude);
            break;
        }
        detail.tax = price - exclude;
        detail.price = price;
        return price;
      }
      detail.is_naizei = false;
      let tax = price * detail.tax_rate / 100;
      switch (code.tanmatsu) {
        case 1:
          tax = Math.floor(tax);
          break;
        case 2:
          tax = Math.ceil(tax);
          break;
        default:
          tax = Math.round(tax);
          break;
      }
      detail.tax = tax;
      let total = price + tax;
      detail.price = total;
      return total;
    },
    filterdReceiptByPayment: function (payment, currentDetails, issuedDetails) {
      let current = _.sumBy(currentDetails, "price");
      let issued = _.sumBy(issuedDetails, "price");
      return payment.total_price - payment.discount_price + current + issued;
    },
    filterdIssuableAmount: function (payment, currentDetails) {
      let current = _.sumBy(currentDetails, "price");
      let plice = payment + current;
      if (plice < 0) {
        return 0;
      }
      return plice;
    },
    alcoholIcon(value) {
      return value ? "on" : "";
    },
    statusIcon(order) {
      if (order.status === OrderStatus.STATUS_CANCELED) {
        return "cancel";
      }
      if (order.status === OrderStatus.STATUS_PAID) {
        return "paid";
      }
      if (order.is_served === ServedCode.SERVED_DONE) {
        return "serving";
      }
      return "";
    },
    statusPopup(value) {
      if ((value === "payment_finish") || (value === "settle_finish") || (value === "receipt_finish")) {
        return "success";
      }
      return "info";
    },
    taxType(flg: boolean): string {
      if (flg == true) {
        return " (" + translate("hall-0096", "税込") + ")";
      }
      if (flg == false) {
        return " (" + translate("hall-0097", "税抜") + ")";
      }
      return;
    },
    taxPrice(prices): number {
      if (prices.tanka.exclude !== null) {
        return prices.tanka.exclude;
      }
      return prices.tanka.include;
    },
    taxTotal(prices): number {
      if (prices.total.exclude !== null) {
        return prices.total.exclude;
      }
      return prices.total.include;
    },
    isOpen(value) {
      return value ? "open" : "";
    },
  },
});
